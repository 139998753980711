<template>
  <v-dialog
    v-model="dialog"
    :max-width="options.width"
    :width="options.width"
    :style="{ zIndex: options.zIndex }"
    class="elevation-1"
    @keydown.esc="cancel"
  >
    <v-card>
      <v-toolbar
        class="pt-0 pb-0 d-flex align-center"
        style="height: 35px"
        dark
        :color="options.color"
        dense
        flat
      >
        <v-toolbar-title class="white--text small">
          {{
            title
          }}
        </v-toolbar-title>
      </v-toolbar>
      <v-card-text
        v-show="!!message"
        class="pb-0"
      >
        <v-container class="pl-0 pr-0">
          <v-row class="pl-0 pr-0">
            <v-col
              cols="12"
              md="4"
              class="d-flex justify-center align-center"
            >
              <img
                class="logotipo"
                alt="Delete-Confirm-IMG"
                :src="options.imgBox"
                width="150"
              />
            </v-col>
            <v-col
              cols="12"
              md="8"
            >
              <v-row class="mb-5 pt-5">
                <v-col>
                  <v-form
                    ref="form"
                    class="multi-col-validation"
                    lazy-validation
                  >
                    <p style="font-size: 18px">
                      {{ message }}
                    </p>
                    <v-text-field
                      v-if="options.activarComentario"
                      v-model="options.comment"
                      label="Comentario"
                      outlined
                      dense
                      placeholder="¿Por qué desea denegar el registro?"
                      hide-details
                    ></v-text-field>

                    <v-row v-if="options.activarNewVersion">
                      <v-col cols="7">
                        <v-text-field
                          ref="refNuevaVersion"
                          v-model="options.newVersion"
                          label="Versión"
                          counter="10"
                          maxlength="10"
                          outlined
                          dense
                          placeholder="Ingrese la versión asignada"
                          :rules="[
                            (v) => !!v || 'El campo Versión es obligatorio',
                            (v) => v.length <= 10 || 'Este campo solo puede tener 10 caracteres',
                          ]"
                        ></v-text-field>
                      </v-col>
                      <v-col cols="5">
                        <v-text-field
                          ref="refBuild"
                          v-model="options.build"
                          type="Number"
                          min="0"
                          label="Build"
                          counter="3"
                          maxlength="3"
                          outlined
                          dense
                          :rules="[
                            (v) => !!v || 'El campo Build es obligatorio',
                            (v) => v.length <= 3 || 'Este campo solo puede tener 3 dígitos',
                            (v) => isPositiveNumber(v) || 'El campo no tiene el formato correcto',
                          ]"
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-form>
                </v-col>
              </v-row>
              <v-row>
                <v-col class="d-flex justify-end">
                  <v-btn
                    color="warning"
                    text
                    @click.native="cancel"
                  >
                    Cancelar
                  </v-btn>
                  <v-btn
                    v-if="options.activarNewVersion"
                    color="success darken-1"
                    text
                    @click.native="agree"
                  >
                    Aceptar
                  </v-btn>
                  <v-btn
                    v-else
                    color="error darken-1"
                    text
                    @click.native="agree"
                  >
                    Aceptar
                    <v-icon
                      right
                      dark
                    >
                      {{ iconoDeletebtn }}
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mdiDeleteVariant, mdiDelete } from '@mdi/js'

export default {
  data: () => ({
    iconoDeletebtn: mdiDelete,
    iconoDelete: mdiDeleteVariant,
    dialog: false,
    resolve: null,
    reject: null,
    message: null,
    title: null,
    options: {
      color: 'primary',
      width: 550,
      zIndex: 200,
      activarComentario: false,
      activarNewVersion: false,
      comment: '',
      newVersion: '',
      build: 0,
      imgBox: '/images/clip-deleting-files.png',
    },
  }),
  methods: {
    open(title, message, options) {
      this.dialog = true
      this.title = title
      this.message = message
      this.options = Object.assign(this.options, options)

      return new Promise((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      })
    },
    async agree() {
      if (this.$refs.form.validate()) {
        await this.resolve(true)
        this.dialog = false
        this.$refs.form.resetValidation()
      }
    },
    cancel() {
      this.$refs.form.resetValidation()
      this.resolve(false)
      this.dialog = false
    },

    isPositiveNumber(s) {
      if (s >= 0 && s <= 999) {
        return true
      }

      return false

      // const regexp = /^([1-9]+\\d*)|[0]/

      // return regexp.test(s)
    },
  },
}
</script>
