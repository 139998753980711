<template>
  <div>
    <div class="mb-3">
      <Breadcrumbs :items="items" />
    </div>
    <tablaComponent
      ref="tabla"
      :headers="headers"
      :titulo-tabla="tituloTabla"
      :url="url"
      :cargando="cargando"
      :have-actions="haveActions"
      :have-add="haveAdd"
      :have-edit="haveEdit"
      :have-delete="haveDelete"
      :have-copy-item="haveCopyItem"
      :search="search"
      :titulo-search="tituloSearch"
      @nuevoform="abrirForm()"
      @editform="editItem($event)"
      @deleteitem="deleteItem($event)"
      @copyform="copyItem($event)"
      @dialogSearch="cargarSearch()"
      @refreshSearch="refreshSearch()"
    >
      <template v-slot:filterSearch>
        <v-row>
          <v-col cols="12">
            <v-autocomplete
              v-model="filterFilesSelected"
              :items="filterFiles"
              item-value="id"
              item-text="name"
              outlined
              dense
              chips
              small-chips
              label="Filtrar por"
              multiple
              hide-details
            >
              <template v-slot:selection="data">
                <v-chip
                  class="elevation-2 my-1"
                  v-bind="data.attrs"
                  :input-value="data.selected"
                  close
                  @click="data.select"
                  @click:close="removeFilterFilesSelected(data.item)"
                >
                  {{ data.item.name }}
                </v-chip>
              </template>
            </v-autocomplete>
          </v-col>
          <!-- Título -->
          <v-col
            v-if="isTitleSelected"
            cols="12"
          >
            <v-text-field
              v-model="titleSearch"
              label="Título"
              outlined
              dense
              placeholder="Buscar por título"
              hide-details
            ></v-text-field>
          </v-col>
          <!-- Fecha -->
          <v-col
            v-if="isPublicationDateSelected"
            cols="12"
          >
            <v-menu
              v-model="menu3"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              max-width="290px"
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="publicationDateSearch"
                  label="Fecha de Publicación"
                  placeholder="Fecha de Publicación"
                  persistent-hint
                  :prepend-inner-icon="icons.mdiCalendar"
                  outlined
                  dense
                  readonly
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                v-model="publicationDateSearch"
                no-title
                @input="menu3 = false"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </template>
      <template v-slot:[`item.title`]="{ item }">
        <div class="truncate_title">
          {{ item.title }}
        </div>
      </template>
      <template v-slot:[`item.shortMessage`]="{ item }">
        <div class="truncate">
          {{ item.shortMessage }}
        </div>
      </template>
      <template v-slot:[`item.status`]="{ item } ">
        <v-chip
          v-if="item.status === 'sending'"
          small
          class="ma-2 elevation-1"
          color="warning"
          text-color="white"
        >
          Enviado
        </v-chip>
        <v-chip
          v-if="item.status === 'pending'"
          small
          class="ma-2 elevation-1"
          color="primary"
          text-color="white"
        >
          Pendiente
        </v-chip>
        <v-chip
          v-if="item.status === 'approved'"
          small
          class="ma-2 elevation-1"
          color="success"
          text-color="white"
        >
          Aprobado
        </v-chip>
      </template>
      <template v-slot:[`item.link`]="{ item } ">
        <a
          :href="item.link"
          target="_blank"
        >{{ item.link }}</a>
      </template>
      <template v-slot:[`item.largeMessage`]="{ item } ">
        <div class="truncate">
          {{ item.largeMessage }}
        </div>
      </template>
    </tablaComponent>
    <!-- Formulario de Confirmación -->
    <confirm ref="confirm"></confirm>
    <v-snackbar
      v-model="notificaciones.snackbar"
      :bottom="notificaciones.y === 'bottom'"
      :color="notificaciones.color"
      :left="notificaciones.x === 'left'"
      :multi-line="notificaciones.mode === 'multi-line'"
      :right="notificaciones.x === 'right'"
      :timeout="notificaciones.timeout"
      :top="notificaciones.y === 'top'"
      :vertical="notificaciones.mode === 'vertical'"
    >
      {{ notificaciones.text }}
      <template v-slot:action="{ attrs }">
        <v-btn
          dark
          text
          v-bind="attrs"
          @click="notificaciones.snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {
  mdiPencil, mdiDelete, mdiArrowLeft, mdiContentCopy,
  mdiArrowRight, mdiCalendar,
} from '@mdi/js'
import { ref, reactive, computed } from '@vue/composition-api'
import moment from 'moment'
import HTTP from '@/utils/axios/axios-config-base'
import { useRouter } from '@/utils'
import tablaComponent from '@/components/tablaComponent.vue'
import Confirm from '@/components/Confirm.vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'

export default {
  components: { Confirm, Breadcrumbs, tablaComponent },
  created() {
    if (this.$route.params.notificaciones != null) {
      this.notificaciones.snackbar = this.$route.params.notificaciones.snackbar
      this.notificaciones.text = this.$route.params.notificaciones.text
      this.notificaciones.color = this.$route.params.notificaciones.color
    }
  },
  setup() {
    const { router } = useRouter()
    const tituloTabla = ref('Notificaciones')
    const url = ref(`${HTTP.defaults.baseURL}marketing-api/notifications?alertType=notification`)
    const confirm = ref(null)
    const cargandoTabla = ref(false)
    const cargando = ref(false)
    const haveActions = ref(true)
    const cargandoEditar = ref(false)
    const cargandoDelete = ref(false)
    const headers = ref([
      {
        text: 'Título', sortable: true, value: 'title', width: '150',
      },
      {
        text: 'Mensaje Corto', sortable: true, value: 'shortMessage', width: '150',
      },

      // { text: 'Url', sortable: false, value: 'link', align: 'left' },
      {
        text: 'Fecha de Envío',
        value: 'publicationDate',
        width: '200',
        sortable: true,
        align: 'center',
        formatter: x => (x ? moment(x).format('DD-MM-YYYY') : null),
      },

      {
        text: 'Fecha de Modificación', value: 'updateAt', width: '200', formatter: x => (x ? moment(x).format('DD-MM-YYYY HH:mm') : null),
      },
      {
        text: 'Alcance', sortable: false, value: 'addressee', width: '150', align: 'center',
      },
      {
        text: 'Estado', sortable: false, value: 'status', width: '150', align: 'center',
      },
      {
        text: 'Acción', value: 'actions', align: 'center', width: '180', sortable: false,
      },
    ])
    const notificaciones = reactive({
      snackbar: false,
      color: '',
      mode: '',
      text: '',
      timeout: 3000,
      x: null,
      y: 'top',
    })
    const tabla = ref(null)
    const search = ref({})
    const tituloSearch = ref('Buscar Notificaciones')
    const filterFiles = [
      { id: 'title', name: 'Título' },
      { id: 'publicationdate', name: 'Fecha de Publicación' },
    ]
    const filterFilesSelected = ref([])
    const isTitleSelected = computed(() => filterFilesSelected.value.includes('title'))
    const titleSearch = ref('')
    const isLocationSelected = computed(() => filterFilesSelected.value.includes('location'))
    const locationSearch = ref('')
    const menu3 = ref(false)
    const isPublicationDateSelected = computed(() => filterFilesSelected.value.includes('publicationdate'))
    const publicationDateSearch = ref('')

    const permissions = localStorage.getItem('permissions')
    const haveDelete = ref(true)
    const haveEdit = ref(true)
    const haveAdd = ref(true)
    const haveCopyItem = ref(true)

    const items = reactive([
      {
        text: 'Inicio',
        disabled: false,
        href: '/',
      },
      {
        text: `${tituloTabla.value}`,
        disabled: true,
        href: '/notificaciones',
      },
    ])

    return {
      headers,
      router,
      tituloTabla,
      url,
      confirm,

      // loadin
      cargando,
      haveActions,
      haveCopyItem,
      haveAdd,
      haveEdit,
      haveDelete,
      tabla,
      search,
      tituloSearch,
      filterFiles,
      filterFilesSelected,
      isTitleSelected,
      titleSearch,
      isLocationSelected,
      locationSearch,
      menu3,
      isPublicationDateSelected,
      publicationDateSearch,
      items,

      // icons
      icons: {
        mdiPencil,
        mdiDelete,
        mdiArrowLeft,
        mdiArrowRight,
        mdiContentCopy,
        mdiCalendar,
      },
      notificaciones,
      cargarSearch: () => {
        const searchAll = {}
        if (filterFilesSelected.value.includes('title')) {
          if (titleSearch.value !== '') {
            searchAll.Title = titleSearch.value
          }
        }
        if (filterFilesSelected.value.includes('location')) {
          if (locationSearch.value !== '') {
            searchAll.Location = locationSearch.value
          }
        }
        if (filterFilesSelected.value.includes('publicationdate')) {
          if (publicationDateSearch.value !== '') {
            searchAll.PublicationDate = publicationDateSearch.value
          }
        }
        search.value = searchAll
      },
      refreshSearch: () => {
        filterFilesSelected.value = []
        search.value = {}
      },
      removeFilterFilesSelected: async item => {
        const index = filterFilesSelected.value.indexOf(item.id)
        if (index >= 0) filterFilesSelected.value.splice(index, 1)
      },
      abrirForm: () => {
        cargando.value = true
        setTimeout(() => {
          router.push({ name: 'crear_notificaciones' })
          cargando.value = false
        }, 500)
      },
      editItem: item => {
        router.push({ name: 'editar_notificaciones', params: { id: item.id } })
      },
      copyItem: item => {
        router.push({ name: 'duplicar_notificaciones', params: { id: item.id } })
      },
      deleteItem: async item => {
        if (item.status === 'sending') {
          notificaciones.color = 'error'
          notificaciones.text = 'No se puede eliminar una notificación que ha sido enviada'
          notificaciones.snackbar = true
        } else {
          confirm.value
            .open(
              'Eliminar notificación',
              '¿Está seguro que desea eliminar la notificación seleccionada?',
              {
                color: 'primary',
                width: '500',
              },
            )
            .then(confirm => {
              if (confirm) {
                const URL = `${HTTP.defaults.baseURL}marketing-api/notifications/${item.id}`
                const resp = HTTP.delete(URL)
                resp.then(val => {
                  if (val.status === 200) {
                    notificaciones.color = 'success'
                    notificaciones.text = 'El registro se ha eliminado satisfactoriamente'
                    notificaciones.snackbar = true
                    tabla.value.listar()
                  } else {
                    notificaciones.color = 'error'
                    notificaciones.text = 'Ha ocurrido un error mientras se eliminaba el registro'
                    notificaciones.snackbar = true
                  }
                })
              } else {
                notificaciones.color = 'info'
                notificaciones.text = 'Operación cancelada'
                notificaciones.snackbar = true
              }
            })
        }
      },
    }
  },
}
</script>

<style>
  .truncate_title {
      max-width: 15vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
  .truncate {
      max-width: 15vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
  }
</style>
